import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, distinctUntilChanged, of, shareReplay, switchMap, tap } from 'rxjs';
import { environment } from 'environments/environment';
import { ProductsPayload, ProductsResponse } from '../Models/productsRequest.model';
import { SystemColor } from '../Models/systemColor.model';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MenuItem, MenuItemType, ParamType } from '../Models/menuItem.model';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  private URL = `${environment.apiUrl}/Products/`;
  private productsPayloadSubject = new BehaviorSubject<ProductsPayload | null>(null);
  private activeTopMenuIdSubject = new BehaviorSubject<string | null>(null);
  public activeTopMenuId$ = this.activeTopMenuIdSubject.asObservable();
  public productsPayload$ = this.productsPayloadSubject.asObservable().pipe(distinctUntilChanged());

  public products$ = this.productsPayload$.pipe(
    switchMap(payload =>
      this.http.post<ProductsResponse>(
        `${this.URL}GetProductList`,
        payload
      ).pipe(
        catchError(error => {
          console.error(error);
          return of(null); // or handle error appropriately
        })
      )
    ),
    shareReplay(1)
  );
  private menuItemsSubject = new BehaviorSubject<MenuItem[] | null>(null);
  menuItems$ = this.menuItemsSubject.asObservable();
  private _http: any = null;
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this._http = http;
    this.subscribeToPayloadChanges();
  }
  public setActiveTopLevelMenu(activeCategoryId: string) {
    const newId = this.getActiveTopMenuId(activeCategoryId);
    this.activeTopMenuIdSubject.next(newId.toString());
  }
  getActiveTopCategoryId() {
    return this.activeTopMenuIdSubject.getValue();
  }
  getMenuItems(): Observable<MenuItem[]> {
    return this.http.get<MenuItem[]>(`${this.URL}GetMenu`).pipe(
      tap(res => {
        this.menuItemsSubject.next(res);
      })
    );
  }

  getActiveTopMenuId(activeCategoryId: string) {
    const menuItems = this.menuItemsSubject.getValue();
    let topMenuId;

    if (menuItems) {
      for (const menuItem of menuItems) {
        topMenuId = this.findTopLevelCategoryId(menuItem, activeCategoryId);
        if (topMenuId) {
          break;
        }
      }
    }

    return topMenuId;
  }

  findTopLevelCategoryId(menuItem, targetValue) {
    if (menuItem.params && menuItem.params[0] && menuItem.params[0].value == targetValue) {
      return menuItem.params[0].value;
    }
    if (menuItem.subMenu?.length) {
      for (const subMenuItem of menuItem.subMenu) {
        const result = this.findTopLevelCategoryId(subMenuItem, targetValue);
        if (result !== null) {
          return result;
        }
      }
    }
    return null;
  }

  public initProductsPayload() {
    // Check if activeTopMenuId$.value exists and update category
    const activeCategories = this.route.snapshot.queryParams['categories'];
    this.route.queryParams.subscribe(queryParams => {
      // Create a new config object with default values
      // queryParams['categories'] && this.setActiveTopLevelMenu(queryParams['categories'].split(',')[0]);
      // Create a new config object with default values
      const newConfig: ProductsPayload = {
        pageSize: queryParams['pageSize'] || 44, // Use the query param if available, otherwise use default (44)
        pageNumber: queryParams['pageNumber'] || 1,
        brands: queryParams['brands'] ? queryParams['brands'].split(',') : [],
        tags: queryParams['tags'] ? queryParams['tags'].split(',') : [],
        sortBy: queryParams['sortBy'] || SortOptions.NewestArrivals,
        categories: queryParams['categories']
          ? queryParams['categories']?.split(',')
          : [activeCategories ? activeCategories : ['99', '139']],
      };
      this.productsPayloadSubject.next(newConfig);
    });
  }
  initTopCategoryId() {
    const activeCategories = this.route.snapshot.queryParams['categories'];
    this.setActiveTopLevelMenu(activeCategories?.split(',')[0]);
  }
  getSystemColor(): Observable<SystemColor[]> {
    return this.http.get<SystemColor[]>(`${this.URL}GetSystemColor/`);
  }
  handleMenuItem(menuItem: MenuItem) {
    if (menuItem.itemType === MenuItemType.Category) {
      menuItem.params?.forEach(param => {
        if (param.paramType === ParamType.Category) {
          this.updatePayloadCategoriesFromMenu(param.value.toString());
          this.setActiveTopLevelMenu(param.value.toString());
        } else if (param.paramType === ParamType.Tag) {
          this.updatePayloadByType(param.value.toString(), 'tags', null);
        } else if (param.paramType === ParamType.Brand) {
          this.updatePayloadByType(param.value.toString(), 'brands', null);
        }
      });
    } else {
      const topCategoryId = this.activeTopMenuIdSubject.getValue();
      const queryParams: any = {};
      queryParams.categories = topCategoryId;
      // Update the URL without reloading the page
      this.router.navigate(['/main/products' + menuItem?.url], {
        queryParams,
        queryParamsHandling: 'merge',
      });
    }
  }

  updatePayloadCategoriesFromMenu(categoryId: string) {
    let payload: ProductsPayload | null = this.productsPayloadSubject.getValue();
    if (payload && this.isTopLevelCategory(categoryId)) {
      payload['categories'] = [categoryId];
      payload['brands'] = [];
      payload['tags'] = [];
      payload['sortBy'] = SortOptions.NewestArrivals;
      payload['pageNumber'] = 1;
    } else if (payload) {
      const topCategory = payload['categories'] ? payload['categories'][0] : undefined;
      payload['categories'] = [topCategory, categoryId].filter(Boolean) as string[];
    }
    this.productsPayloadSubject.next(payload);
  }

  isTopLevelCategory(id: string) {
    const menuItems = this.menuItemsSubject.getValue();
    return menuItems?.some(menuItem =>
      menuItem.params.some(param =>
        param.paramType === ParamType.Category && param.value.toString() === id
      )
    ) || false;
  }
  updatePayloadByType(id: string | number, type: string, isChecked: boolean | null = true) {
    let payload: ProductsPayload | null = this.productsPayloadSubject.getValue();
    if (payload) {
      switch (type) {
        case 'pageNumber':
        case 'sortBy': {
          payload[type] = +id;
          break;
        }
        default: {
          if (isChecked === null) {
            payload[type] = [id];
          } else if (isChecked) {
            payload[type] = [...(payload[type] || []), id];
          } else {
            payload[type] = (payload[type] || []).filter(existingId => existingId !== id);
          }
          break;
        }
      }
      this.productsPayloadSubject.next(payload);
    }
  }

  subscribeToPayloadChanges() {
    this.productsPayloadSubject.subscribe(payload => {
      if (payload) {
        this.updateRouterState(payload);
      }
    });
  };

  updateRouterState(payload) {
    const queryParams: any = {};
    queryParams.pageNumber = payload.pageNumber;
    ['brands', 'tags', 'categories', 'sortBy', 'seasons'].forEach(key => {
      if (Array.isArray(payload[key])) {
        queryParams[key] = payload[key].length ? payload[key].join(',') : null;
      } else {
        queryParams[key] = payload[key] || null;
      }
    });
    // Get the current URL
    const currentUrl = this.router.url;
    // Check if the current URL contains the target path ('products/list')
    if (currentUrl.includes('/products/list')) {
      // If yes, update the query parameters
      this.router.navigate([], {
        relativeTo: this.route,
        queryParamsHandling: 'merge',
        queryParams,
      });

    }
  }
  resetFilters() {
    const activeTopCategoryId = this.activeTopMenuIdSubject.getValue();
    const newPayload: ProductsPayload = {
      pageSize: 44, // Use the query param if available, otherwise use default (44)
      pageNumber: 1,
      brands: [],
      tags: [],
      sortBy: SortOptions.NewestArrivals,
      categories: [activeTopCategoryId!],
    };
    this.productsPayloadSubject.next(newPayload);
  }

  getProductById(productId: number): Observable<any> {
    return this._http.get(`${this.URL}product/${productId}`).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      shareReplay(1)
    );
  }
}

export type PayloadFilterType = 'pageNumber' | 'brands' | 'categories' | 'tags' | 'sortBy' | 'seasons';
export enum SortOptions {
  NewestArrivals = 1,
  PriceLowToHigh,
  PriceHighToLow,
}
