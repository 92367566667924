import { Type } from "@angular/core";
import { TypeId } from "./user.model";

export class Organization {
    id: number;
    name: string;
    address: string;
    countryCode: string;
    country: string;
    webSite: string;
    status: number;
    logo: string;
    notes: string;
    //deliveryAddress
    typeId: TypeId;
    creationDate: Date;
}

export enum StatusId {
    Enabled = 1,
    Disabled = 2,
    Deleted = 3,
}